import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  { path: "", redirectTo: "property/1/reservations/create", pathMatch: "full" },
  {
    path: "property/:id/reservations/create",
    loadComponent: () => import('./base/component').then(component => component.BaseComponent)
  },
  {
    path: "property/:id/reservations/:idPickup",
    loadComponent: () => import('./base-detail/component').then(component => component.BaseDetailComponent)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
