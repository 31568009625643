import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app/app.routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ReferenceEventsSourceService } from './app/events.service';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TravelSatusService } from './app/travel-status/service';
import { GuetsPickupWelcomeService } from './app/welcome/service';
import { GuestsPickupWaitingShuttleService } from './app/waiting-shuttle/service';
import { GuestsPickupLocationService } from './app/location/service';
import { LanguageSelectionService } from './app/header/service';
import { GuesthubPickupRequestingShuttleService } from './app/requesting-shuttle/service';
import { GuestsPickupPickMeUpService } from './app/pick-me-up/service';
import { GuestsPickupCreateService } from './app/base/service';

if (environment.production) {
    enableProdMode();
}
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }), ClickOutsideModule, FormsModule, LeafletModule),
        GuestsPickupCreateService,
        GuestsPickupPickMeUpService,
        GuesthubPickupRequestingShuttleService,
        LanguageSelectionService,
        GuestsPickupLocationService,
        GuestsPickupWaitingShuttleService,
        GuetsPickupWelcomeService,
        TravelSatusService,
        TranslateService,
        ReferenceEventsSourceService,
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
    .catch(err => console.error(err));
